

.aws-btn {

--button-default-height: 51px;
--button-default-font-size: 14px;
--button-default-border-radius: 2px;
--button-horizontal-padding: 20px;
--button-raise-level: 4px;
--button-hover-pressure: 1;
--transform-speed: 0.175s;
--button-primary-color:rgb(154, 179, 90);
--button-primary-color-dark: rgb(69, 104, 0);
--button-primary-color-light: #ffffff;
--button-primary-color-hover: rgb(81, 121, 1);
--button-primary-color-active: rgb(81, 121, 1);
--button-primary-border: none;
--button-secondary-color: #f2f6f9;
--button-secondary-color-dark: #1360a4;
--button-secondary-color-light: #1e88e5;
--button-secondary-color-hover: #e1eaf1;
--button-secondary-border: none;
--button-anchor-color: #0e4f88;
--button-anchor-color-dark: #072743;
--button-anchor-color-light: #ffffff;
--button-anchor-color-hover: #0d4a7f;
--button-anchor-border: none;
--button-font-family: "Rubik", sans-serif;
--button-font-weight: 400;
--button-default-font-size: 16px;
}