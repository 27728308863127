.client-col-panels {
  display: flex;
  min-width: 100%;
}

.client-col-panel {
  color: #000;
  text-align: center;
  align-items: center;
  background-color: #f7f7f7;
  //transition: flex 0.2s ease-in;
  font-size: 20px;
  background-size: cover;
  background-position: center;
  flex: 1;
  justify-content: center;
  display: flex;
  flex-direction: column;
  cursor: col-resize;
  &:hover {
    background: #efeded;
  }
  .slot-content {
    .input-group {
      background: transparent !important;
      color: white !important;
      border: 0 !important;
      border-bottom: 1px solid white !important;

      input,
      select,
      label {
        color: white !important;
        border: 0 !important;
        background: transparent !important;
      }
    }
    .btn {
    }
    .btn-danger {
      color: white !important;
      border: 0 !important;
      background: transparent !important;
    }
  }
}

.client-col-panel > * {
  margin: 0;
  width: 100%;
  transition: transform 0.5s;
  flex: 1 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  transform-origin: top center;
}
.client-col-panel {
  font-size: 12px;

  .reducable {
    transform: scale(0.97) !important;

    &.dish-slot-name {
      width: 22px;
    }
  }

  &.open {
    flex: 5;

    font-size: 14px;
    transform: scale(1);
    .reducable {
      transform: scale(1) !important;
      &.dish-slot-name {
        width: 40px;
      }
    }
  }
}

.client-col-panel p {
  font-size: 0.8em;
  opacity: 0.3;
}

.client-col-panel p:nth-child(2) {
  font-size: 2.5em;
}
.dish-slot-view {
  cursor: pointer;
}
.dish-slot-container {
  transition: all 200ms ease;
  &:hover {
    box-shadow: inset 0px -2px 3px -2px #000000;
  }
}
