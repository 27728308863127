$theme-colors: (
  "primary": rgb(154, 179, 90),
  "secondary": rgb(69, 104, 0),
  "tertiary": #253858,
  "success": #36767e,
  "info": #818686,
  "warning": #ffcc99,
  "dark": #222222,
  "danger": #dc5f45,
  "light": #ffffff,
  "default": #e2e8ed,
  "trans": transparent,
);

$border-radius: 0px;
$ligthtingIntensity: 10%;
$darkeningIntensity: 10%;
$body: #e2e8ed;
